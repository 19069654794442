@import 'node_modules/@eg/elements/styles/ergo-one/colors';
@import 'node_modules/@eg/elements/styles/breakpoints';
@import '../../../scss/mixins';
.inputHintError {
    color: $theme-error-color !important;
}

.innerContributionBox {
    text-align: center;
}

.caption {
    font-size: 30px;
    margin-bottom: 1em;
}

.summaryLink {
    margin-top: 36px;
}

.lastChildElement {
    height: 0;
    max-height: 0;
}

.contributionBox {
    margin-bottom: 120px;
}

.formSectionBorder {
    border-top: 1px solid $theme-light-gray;
}

.nextCheckboxTile {
    margin-top: 24px;
}

.contributionBoxCol {
  display: none;
}

.contributionBoxMobile {
  display: block;
  margin-bottom: 80px;
}

@include breakpoint($breakpoint-m) {
    .contributionBoxCol {
        order: 2;
        display: block;
    }
    .contributionBox {
        margin-top: 79px;
        margin-left: 80px;
    }
    .contributionBoxMobile {
      display: none;
      margin-bottom: 0;
    }
}

.pseudoLabel {
    border-top: 1px solid $theme-light-gray;
    >div {
        margin-bottom: 4px !important;
    }
}

@media screen and (max-width: $breakpoint-m - 1) {
    .pseudoLabel {
        border-top: none;
        >div {
            margin-bottom: 0 !important;
            h1 {
                font-size: 14px !important;
                font-weight: 500 !important;
            }
        }
        &.paddingFix {
            padding-bottom: 0 !important;
        }
    }
}

.emptyFormSection {
    padding-bottom: 0 !important;
    margin-bottom: 28px;
    @include breakpoint($breakpoint-m) {
        margin-bottom: 32px;
    }
}

.fatLabel {
    font-size: inherit;
    font-weight: inherit;
}

@media (min-width: 480px) {
    .fatLabel {
        font-size: 18px;
        font-weight: 700;
    }
}
