@media print {
  @page {
    size: A4;
    margin: 12.375mm;
  }

  body {
    font-size: 12pt;

    footer, .ee_step-navigation { display: none; }
    header { 
      box-shadow: none !important;

      #ergo-logo {
        position: absolute;
        right: 0;
        top: 0;
        width: 43.31mm;
        height: 13.124mm;
      }
    }

    header + .esc_box {
      padding: 0 !important;
    }

    #page-headline {
      padding-top: 14mm;
      color: #8e0038;
      font-family: "Fedra Serif", Georgia, "Times New Roman", serif;
      font-size: 22pt !important;
    }

    #premium-box {
      border: 1px solid #8e0038;
      box-shadow: none !important;
      margin-right: 20mm !important;
      width: 100%;
    }
  
    .ee_details__summary .ee_details__right-icon { display: none; }
    .ee_details__collapsible {
      padding-top: 8px;
      height: auto !important;
    }
    .ee_details__content {
      border-bottom: 0 !important;
      padding-bottom: 32px;
    }

    .ee_form-section {
      padding-bottom: 0 !important;
      border-top: 0 !important;

      .ee_form-section__heading { display: none; }
    }

    .esc_grid__wrapper {
      .esc_col { border-width: 2px 12px; }
      .esc_col-m-2 { width: 16.66666667%; }
      .esc_col-m-4 { width: 33.33333333%; }
      .esc_col-m-6 { width: 50%; }
      .esc_col-m-8 { width: 66.66666667%; }
      .esc_col-m-10 { width: 83.33333333%; }      
      .esc_col-m-12 { width: 100%; }
    }
  
    .print-hide { display: none !important; }
  }
}
