@import 'node_modules/@eg/elements/styles/ergo-one/colors';
@import 'node_modules/@eg/elements/styles/breakpoints';
@import '../../../scss/mixins';


.summaryBox {
  page-break-inside : avoid;

  :global(.ee_details__title) {
    font-weight: bold;
  }
}

.summaryLink {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 12px;
  font-weight: bold;
}

.summaryLinkLabel {
  display: none;

  @include breakpoint($breakpoint-m) {
    display: inline-block;
  }
}

.summaryLinkIcon {
  margin-left: 15px;
}
