@import 'node_modules/@eg/elements/styles/ergo-one/colors';
@import 'node_modules/@eg/elements/styles/breakpoints';

.downloadButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.sendOrderWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
}
