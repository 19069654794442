@import "node_modules/@eg/elements/styles/ergo-one/colors";
.container {
    background-color: $theme-additional-3-lighter;
    border: 1px solid $theme-primary;
}

.innerContainer {
    text-align: center;
}

.iconBox {
    margin-bottom: 10px;
}

.title {
    font-size: 1em;
    font-weight: 700;
}

.priceRow {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 29px;
    position: relative;
}

.descriptionRow {
    background: none;
    position: relative;
    margin-bottom: 1em;
}

.descriptionRowText {
    margin-left: 1em;
    text-align: left;
    hyphens: none;
}

.descriptionRowIcon {
    width: 1em;
    position: absolute;
    top: 0;
    left: -0.2em;
}