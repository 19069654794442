@import 'node_modules/@eg/elements/styles/ergo-one/colors';
@import 'node_modules/@eg/elements/styles/breakpoints';
@import '../../../scss/styles.scss';

$footer-color: #fff;

.footer {
    background-color: $theme-brand-color-lighter;
    color: $footer-color;
    font-size: 12px;

    @include breakpoint($breakpoint-m) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $footer-height;
        box-sizing: border-box;
    }
}

.linkToErgo {
    color: $footer-color;
}

.socialMedia {
    display: flex;
    align-items: center;
    margin-top: 20px;
    @include breakpoint($breakpoint-m) {
        margin-top: 0;
    }
    a {
        color: $footer-color;
        margin: 0 5px;
    }
}
