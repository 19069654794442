@import 'node_modules/@eg/elements/styles/ergo-one/colors';
.checkboxTileContainer {
    border: 1px solid $theme-darker-gray;
    margin-bottom: 0;
}

.disabled {
    cursor: not-allowed;
}

.clickable {
    cursor: pointer;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconContainer {
    flex: 0 0 40px;
    background-color: transparent;
    background: none;
    max-height: 48px;
}

.labelContainer {
    flex: 1 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    background: none;
}

.tooltipContainer {
    margin-left: 8px;
    margin-right: 16px;
    flex: 0 0 40px;
    max-height: 40px;
}

.inputContainer {
    pointer-events: none;
    flex: 0 0 22px;
    background-color: transparent;
    display: flex;
    align-items: center;
    background: none;
    label {
        max-width: 0;
        width: 0;
    }
}