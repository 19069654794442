@import 'node_modules/@eg/elements/styles/ergo-one/colors';
@import 'node_modules/@eg/elements/styles/ergo-one/misc';
// @import 'node_modules/@eg/elements/styles/ergo-one/typography';
$small__padding: 12px;
$normal__padding: 24px;
$border__width: 3px;
$small__border__witdth: 1px;
.boxError {
    padding: $normal__padding;
    border: $border__width solid $theme-brand-color-dark;
    border-radius: $base__border-radius--small;
    margin: 40px 0;
    font-weight: bold;
}

.messageError {
    // TODO: use variables from typography.scss when the bugs are fixed by Erg0-Elements Team
    // font-weight: $base__heading__font-weight;
    // font-size: $base--large-screen__font-size + 2;
    font-size: 18px;
}

.telError {
    display: inline-block;
    font-size: 28px;
    margin-left: 0.5em;
}

.phoneWrapper {
    display: flex;
    align-items: center;
}

.phoneIcon {
    flex: 0 0 30px;
    color: $theme-brand-color-light;
    max-height: 30px;
}

.phoneNumber {
    flex: 1 0;
}

.boxWarning {
    padding: $normal__padding;
    border: $small__border__witdth solid $theme-light-gray;
    border-radius: $base__border-radius--small;
    margin: 40px 0;
}

.messageWarning {
    font-size: inherit;
}

.telWarning {
    display: inline-block;
    font-size: inherit;
    margin-left: 0.5em;
}