@import '../../../scss/styles.scss';

.header {
  font-size: 19px;

  @include breakpoint($breakpoint-s) {
    font-size: 32px;
  }

}
