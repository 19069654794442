@import '../../../scss/styles.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint($breakpoint-m) {
    height: $header-height;
    box-sizing: border-box;
  }
}
