@import '../../../scss/styles.scss';

$general-template-main-height: calc(100vh - #{$header-height} - #{$footer-height} - #{24px} - #{24px});

.wrapper {
  @include breakpoint($breakpoint-m) {
    box-sizing: border-box;
    min-height: $general-template-main-height;
 }
}
