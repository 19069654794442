@import 'node_modules/@eg/elements/styles/breakpoints';
@import './mixins';

.u-hidden {
    display: none !important;
}

.u-text-align-center {
    text-align: center;
}

.u-padding-top-m {
    padding-top: 30px !important;

    @include breakpoint($breakpoint-l) {
        padding-top: 50px !important;
    }
}

.u-margin-top-m {
    margin-top: 30px !important;

    @include breakpoint($breakpoint-l) {
        margin-top: 50px !important;
    }
}

.u-padding-top-l {
    padding-top: 60px !important;

    @include breakpoint($breakpoint-l) {
        padding-top: 100px !important;
    }
}

.u-padding-bottom-m {
    padding-bottom: 30px !important;

    @include breakpoint($breakpoint-l) {
        padding-bottom: 50px !important;
    }
}

.u-margin-bottom-mobile-m {
    margin-bottom: 30px !important;

    @include breakpoint($breakpoint-l) {
        margin-bottom: 0 !important;
    }
}
