@import './mixins';
@import 'node_modules/@eg/elements/styles/breakpoints';

.navigation-buttons
{
  &__wrapper {
  min-height: 36px;
  width: 200px;
  text-align: center;
  margin: 0 auto;
  
    @include breakpoint($breakpoint-s) {
      display: flex;
      flex-direction: row-reverse;
      width: auto;
      align-items: flex-end;
      justify-content: space-between;
      text-align: left;
      width: 100%;
    }
  }
  
  &__next-button {
    margin-bottom: 10px;
    
    @include breakpoint($breakpoint-s) {
      margin-bottom: 0;
    }
  }
}
