@import 'node_modules/@eg/elements/styles/ergo-one/colors';
@import 'node_modules/@eg/elements/styles/breakpoints';
@import '../../../scss/mixins';
.container {
    box-sizing: border-box;
    background-color: $theme-additional-3-lighter;
    border: 1px solid $theme-primary;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
}

.innerContainer {
    display: block;
    text-align: center;
    position: relative;
    margin-left: 23px;
    margin-right: 23px;
}

.ribbon {
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    background-color: $theme-additional-3;
    width: 4px;
    max-width: 4px;
    border-radius: 2px;
}

.iconBox {
    display: block;
    max-height: 48px;
}

.contentBox {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
}

.tariffBox {
    display: block;
    text-align: center;
}

.title {
    font-weight: 700;
}

.subtitle {
    margin-top: 4px;
}

@include breakpoint($breakpoint-m) {
    .innerContainer {
        display: flex;
        text-align: left;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    .iconBox {
        flex: 0 0 48px;
    }
    .contentBox {
        flex: 1;
        margin-left: 16px;
        margin-right: 0;
    }
}