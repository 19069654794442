@import 'node_modules/@eg/elements/styles/ergo-one/colors';
@import 'node_modules/@eg/elements/styles/breakpoints';
@import '../../../scss/mixins';
.contributionBox {
    margin-top: 24px;
    margin-left: 0;
}

.nextCheckboxTile {
    margin-top: 24px;
}

.formSectionBorder {
    border-top: 1px solid $theme-light-gray;
}

.extra {
    margin-left: 30px;
}

.contributionBoxDesktop {
  display: none;
}
.contributionBoxMobile {
  display: block;
  margin-bottom: 80px;
}

@include breakpoint($breakpoint-m) {
    .contributionBoxDesktop {
        display: block;
    }
    .contributionBoxMobile {
        display: none;
        margin-bottom: 0;
    }
    .contributionBox {
        margin-top: 0;
        margin-left: 80px;
    }
}

.formSectionSubheadline {
    padding-bottom: 0 !important;
}
