@import 'node_modules/@eg/elements/styles/ergo-one/colors';

.priceboxContainer {
  position: relative;

  > span {
    opacity: 0;
  }
}

.visible {
  >span {
    opacity: 1;
    transition: opacity 100ms linear;
  }
}

.spinnerContainer {
  > div {
    background-color: transparent !important;
  }
}

.errorContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 36px;
  color: $theme-error-color;
}
